import React from "react";
import img1 from "../images/cards images/3patti_oneday-300x150.jpeg";
import img2 from "../images/cards images/7updown-300x150.jpeg";
import img3 from "../images/cards images/32card_casino-300x150.jpeg";
import img4 from "../images/cards images/amarakbarantony-300x150.jpeg";
import img5 from "../images/cards images/andarbahar.jpeg";
import img6 from "../images/cards images/bollywood_casino-300x150.jpeg";
import img7 from "../images/cards images/dragontiger-300x150.jpeg";
import img8 from "../images/cards images/highlow-300x150.jpeg";
import img9 from "../images/cards images/index.jpeg";
import img10 from "../images/cards images/matka_virtual-300x150.jpeg";
import img11 from "../images/cards images/race2020-300x150.jpeg";
import img12 from "../images/cards images/sixplayer_poker-300x150.jpeg";

const FeatureCard = () => {
  const data = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ];
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 my-5">
      {data.map((item, i) => (
        <a
          href="https://wa.me/919828045255"
          target="_blank"
          rel="noopener noreferrer"
          key={i}
        >
          <img src={item} alt="" className="h-full w-full cursor-pointer" />
        </a>
      ))}
    </div>
  );
};

export default FeatureCard;
