import logo from "./logo.svg";
import "./App.css";
import TopNav from "./components/TopNav";
import HomeSlider from "./components/HomeSlider";
import SecondSlider from "./components/SecondSlider";
import bannerImg from "../src/images/bannerImg.jpeg";
import cards from "../src/images/cards.gif";
import FeatureCard from "./components/FeatureCard";
import Counter from "./components/Counter";
import WhySection from "./components/WhySection";
import HowItWorks from "./components/HowItWorks";
import {MdOutlineDoNotDisturbAlt} from 'react-icons/md'
import { FaGlobe } from "react-icons/fa";

function App() {
  return (
    <div>
      {/* <Counter /> */}
      <TopNav />
      <HomeSlider />
      <SecondSlider />
      <img src={bannerImg} />
      <div className="text-center mx-auto text-white">
      <a
          href="https://wa.me/919828045255"
          target="_blank"
          rel="noopener noreferrer"
        ><img src={cards} className="text-center mx-auto" /></a>
        <h1 className="text-2xl font-bold">OUR POPULAR LIVE CASINO</h1>
        <p>
          Ambani Casino Provides 24*7 Live Table Dealer Over 200+ Live Games
        </p>
      </div>
      
      <div className="container mx-auto px-4 ">
      <FeatureCard />
      </div>
      <WhySection />
      <HowItWorks />
      
      <div className="bg-white py-8 text-center">
        <div className="mx-auto flex items-center space-x-2 justify-center">
          <MdOutlineDoNotDisturbAlt className="text-red-500" />
          <p>Underage gambling is an offence</p>
        </div>
        <div className="mx-auto flex items-center space-x-2 justify-center">
          <FaGlobe className="text-red-500" />
          <p>Restricted territories</p>
        </div>
      </div>
    </div>
  );
}

export default App;
