import React from "react";
import icon1 from "../images/ic1.png";
import icon2 from "../images/ic2.png";

import icon3 from "../images/ic3.png";
import icon4 from "../images/ic4.png";
import icon5 from "../images/ic5.png";
import icon6 from "../images/ic6.png";

const WhySection = () => {
  const data = [
    {
      id: 1,
      img: icon1,
      heading: "ONLINE ID",
      description:
        "Get your online cricket/sports id with us and instantly start Playing. Message us to get your ID now.",
    },
    {
      id: 2,
      img: icon2,
      heading: "24 /7 SUPPORT",
      description:
        "We have a team of experts who available 24/7 to guide you through our whatsApp chats.",
    },
    {
      id: 3,
      img: icon3,
      heading: "100% TRUSTED SERVICE",
      description:
        "Transparency and accuracy is the most important for us and for our customer. We deal in the most transparent way.",
    },
    {
      id: 4,
      img: icon4,
      heading: "INSTANT WITHDRAWAL & REFILL",
      description:
        "Ambani Book provides instant service of id withdrawal & refilling with min to max amount.",
    },
    {
      id: 5,
      img: icon5,
      heading: "AVAILABLE PAYMENT METHODS",
      description:
        "Multiple methods are available for Payment. Customer can pay via Net Banking, Bank Transfer, Paytm, Google pay, IMPS, PhonePe etc.",
    },
    {
      id: 6,
      img: icon6,
      heading: "HIGH SECURITY",
      description:
        "Security is our Top priority and hence your data and information is safe and is not shared at any Point!",
    },
  ];
  return (
    <div>
      <h1 className="text-3xl font-bold text-white text-center my-4 ">
        <span className="text-yellow-500">Why</span> Ambani Book
      </h1>
      <div className="container mx-auto px-4 lg:px-8 text-white">
        <div className="grid md:grid-cols-3 gap-8 justify-center">
          {data.map((item, i) => (
            <a
              href="https://wa.me/919828045255"
              target="_blank"
              rel="noopener noreferrer"
              key={i}
            >
              <div className="border border-white text-center p-3">
                <img
                  src={item.img}
                  alt=""
                  srcSet=""
                  className="mx-auto w-1/3 h-28"
                />
                <h1 className="text-xl text-yellow-500 font-bold my-4">
                  {item.heading}
                </h1>
                <p>{item.description}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhySection;
