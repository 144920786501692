import React from "react";
import img1 from "../images/1-1.png";
import img2 from "../images/2-1.png";
import img3 from "../images/3.png";
import img4 from "../images/4.png";

const HowItWorks = () => {
  const data = [
    {
      id: 1,
      heading: "Message us on our WhatsApp number",
      img: img1,
    },
    {
      id: 2,
      heading: "Customer Satisfy guaranteed with all information",
      img: img2,
    },
    {
      id: 3,
      heading: "Start depositing & withdrawal instantly",
      img: img3,
    },
    {
      id: 4,
      heading: "Earn from your skills",
      img: img4,
    },
  ];
  return (
    <div>
      <h1 className="text-3xl font-bold text-white text-center my-12 ">
        <span className="text-yellow-500">How</span> it works?
      </h1>
      <div className="container mx-auto w-full flex items-center flex-wrap md:flex-nowrap space-x-4">
        {data.map((item, i) => (
          <div className="w-full text-center text-white" key={i}>
            <a
              href="https://wa.me/919828045255"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={item.img} alt="" srcSet="" className="mx-auto w-24" />
            </a>
            <p className="pb-12 text-sm">{item.heading}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
