import React from "react";
import Slider from "react-slick";
import slider1 from "../images/smaill-slider-1.webp";

import slider2 from "../images/smaill-slider-2.webp";
import slider3 from "../images/smaill-slider-3.webp";
import slider4 from "../images/smaill-slider-4.webp";

import slider5 from "../images/smaill-slider-5.webp";
import slider6 from "../images/smaill-slider-6.webp";
import slider7 from "../images/smaill-slider-7.webp";
import slider8 from "../images/smaill-slider-8.webp";

const SecondSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    marginRight: 10,
  };
  return (
    <div className="text-white mx-8 ">
      <div>
        <a
          href="https://wa.me/919828045255"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Slider {...settings}>
            <div>
              <img src={slider1} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider2} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider3} className="w-full" alt="" />
            </div>

            <div>
              <img src={slider4} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider5} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider6} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider7} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider8} className="w-full" alt="" />
            </div>
          </Slider>
        </a>
      </div>
    </div>
  );
};

export default SecondSlider;
