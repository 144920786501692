import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "../images/headerSlider1.png";
import slider2 from "../images/headerSlider2.jpg";

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="text-white mx-8 ">
      <div>
        <a
          href="https://wa.me/919828045255"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Slider {...settings}>
            <div>
              <img src={slider1} className="w-full" alt="" />
            </div>
            <div>
              <img src={slider2} className="w-full" alt="" />
            </div>
          </Slider>
        </a>
      </div>
    </div>
  );
};

export default HomeSlider;
