import React from "react";
import LogoImg from "../images/logo.png";
import { FaUser, FaUserPlus } from "react-icons/fa";

const TopNav = () => {
  return (
    <div>
      <div className="flex items-center justify-between gap-4 md:gap-2">
        <img src={LogoImg} alt="" srcset="" className="w-24 md:w-full" />

        <div className="bg-yellow-300 flex items-center justify-center space-x-2 w-full text-center py-1 md:py-3 rounded-lg cursor-pointer">
          <FaUserPlus />
          <a
            href="https://wa.me/919828045255"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Sign up</p>
          </a>
        </div>

        <div className="bg-white flex items-center justify-center space-x-2 w-full text-center py-1 md:py-3 rounded-lg cursor-pointer">
          <FaUser />
          <a
            href="https://wa.me/919828045255"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Login</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
